<script lang="ts" setup>
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { computed } from 'vue';
import type { FormResult } from '~ui/types/formResult';

const props = defineProps<FormResult>();

const textAlertVariant = computed(() =>
  twMerge(
    cva('flex w-full items-center justify-center text-center font-semibold', {
      variants: {
        variant: {
          success: 'text-green-500',
          error: 'text-red-500',
          info: 'text-blue-500',
        },
      },
    })({
      variant: props.variant,
    }),
  ),
);
</script>

<template>
  <div :class="textAlertVariant" :data-testid="`ui-alert-message-text-${variant}`">
    <p>{{ message }}</p>
  </div>
</template>
