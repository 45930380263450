<script setup lang="ts">
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { computed, ref, watch } from 'vue';

interface Props {
  isFocused?: boolean;
  disabled?: boolean;
  isError?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isFocused: false,
  disabled: false,
  isError: false,
});

const model = defineModel<string | null>({ default: null });

const emit = defineEmits<{ move: [direction: 'next' | 'back', erase?: boolean] }>();

const boxInput = ref<HTMLInputElement | undefined>(undefined);

const data = computed({
  get() {
    return model.value;
  },
  set(value: string | null) {
    model.value = value;
  },
});

const isCtrlV = (event: KeyboardEvent) => event.key === 'v' && (event.metaKey || event.ctrlKey);
const isKeyAllowed = (key: string) =>
  !/^[0-9]$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight' && key !== 'Tab';

// track item before user prompts Delete / Backspace keypress action
const priorDeletedValue = ref<string | null>(null);
const handleInput = (event: KeyboardEvent) => {
  priorDeletedValue.value = null; // always reset this
  const { key } = event;

  // Allow only numbers, backspace, delete, arrow left and right, tab, and cmd / ctrl + v
  if (isKeyAllowed(key) && !isCtrlV(event)) {
    event.preventDefault();
  } else if (/^[0-9]$/.test(key) && key.trim()) {
    event.preventDefault();
    // a case of numeric prompt and not empty (at least a single digit)
    data.value = key.trim();
  } else if (key === 'Delete' || key === 'Backspace') {
    priorDeletedValue.value = (<HTMLInputElement>event.target).value;
    data.value = null;
  } else if (key === 'ArrowLeft') {
    event.preventDefault();
    emit('move', 'back');
  } else if (key === 'ArrowRight') {
    event.preventDefault();
    emit('move', 'next');
  }
};

const handleHangUp = (event: KeyboardEvent) => {
  const { key } = event;
  if (!boxInput.value) {
    return;
  }

  if (!/^[0-9]$/.test(key) && key !== 'Backspace' && key !== 'Delete') {
    return;
  }

  boxInput.value.value = data.value || '';
  if ((key === 'Backspace' || key === 'Delete') && !data.value) {
    switch (key) {
      case 'Delete':
        if (!priorDeletedValue.value?.trim()) {
          emit('move', 'next', true);
        }

        break;
      default:
        if (!priorDeletedValue.value?.trim()) {
          emit('move', 'back', true);
        }
        break;
    }
  } else if (data.value) {
    emit('move', 'next');
  }
};

watch(
  () => props.isFocused,
  (newVal) => {
    if (newVal) {
      boxInput.value?.focus();
    } else {
      boxInput.value?.blur();
    }
  },
);

const variantClasses = computed<string>(() => {
  return twMerge(
    cva(
      'size-10 rounded-md border border-grey-400 bg-white text-center text-lg caret-transparent outline-0 selection:bg-transparent focus:border-2 focus:border-primary-500',
      {
        variants: {
          disabled: {
            true: 'cursor-not-allowed border-grey-400 bg-grey-100 text-grey-500 focus:border',
          },
          isError: {
            true: 'border-red-400 bg-red-50 text-red-500',
          },
        },
      },
    )({
      disabled: props.disabled,
      isError: props.isError,
    }),
  );
});
</script>

<template>
  <input
    ref="boxInput"
    type="number"
    inputmode="numeric"
    :value="data"
    :class="variantClasses"
    :disabled="disabled"
    @keydown="handleInput"
    @keyup="handleHangUp"
  />
</template>
