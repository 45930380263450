<script setup lang="ts">
import { computed, onMounted, ref, unref, watch } from 'vue';
import type { ValidationProp } from '~ui/types/validationProp';
import { useVuelidate } from '@vuelidate/core';
import { numeric, maxLength } from '@vuelidate/validators';

interface Props {
  enteredCodeScreenReader: string;
  codeDigitInitialLabel: string;
  codeDigitLabel: string;
  disabled?: boolean;
  rules?: ValidationProp;
  nextFocusOnSubmitButton?: boolean;
  focusOnMounted?: boolean;
}

interface CodeItem {
  [key: number]: { focused: boolean; value: string | null };
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  nextFocusOnSubmitButton: false,
  focusOnMounted: false,
  rules: undefined,
});
// dont write to here directly but through event listeners
const otpCode = defineModel<string | null>({ default: null });
const validation = useVuelidate({ otpCode: { ...props.rules, numeric, maxLength: maxLength(6) } }, { otpCode });

// storing prompted value of numeric string
const codeItems = ref<CodeItem>({
  1: { value: null, focused: false },
  2: { value: null, focused: false },
  3: { value: null, focused: false },
  4: { value: null, focused: false },
  5: { value: null, focused: false },
  6: { value: null, focused: false },
});

const setFocus = (index: number, focus: boolean) => {
  codeItems.value[index].focused = focus;
};

const focusOnExternalNext = () => {
  (<HTMLButtonElement | undefined>document.querySelectorAll('button[type="submit"]')[0])?.focus();
};

const next = (currentIndex: number, eraseAfter?: boolean) => {
  const nextIndex = currentIndex + 1;
  if (codeItems.value[nextIndex]) {
    setFocus(currentIndex, false);
    codeItems.value[nextIndex].focused = true;
    if (eraseAfter === true) {
      codeItems.value[nextIndex].value = null;
    }
  } else if (props.nextFocusOnSubmitButton) {
    focusOnExternalNext();
  }
};

const back = (currentIndex: number, eraseBefore?: boolean) => {
  const prevIndex = currentIndex - 1;
  if (codeItems.value[prevIndex]) {
    setFocus(currentIndex, false);

    codeItems.value[prevIndex].focused = true;
    if (eraseBefore === true) {
      codeItems.value[prevIndex].value = null;
    }
  }
};

watch(
  () => codeItems.value,
  () => {
    let _value = '';
    Object.keys(codeItems.value).map((key) => {
      _value += codeItems.value[Number(key)].value?.trim() || '';
    });

    otpCode.value = _value;
  },
  { deep: true },
);

const splitInputToParts = (digits: string) => {
  const items: CodeItem = {};
  digits
    ?.trim()
    .padEnd(6, ' ')
    .split('')
    .forEach((part, index) => {
      items[index + 1] = { value: part.trim() || null, focused: false };
    });
  // flush the value
  codeItems.value = { ...items };
};

watch(
  () => otpCode.value,
  (newVal, oldVal) => {
    // initial set
    if ((oldVal === null || oldVal === undefined) && newVal) {
      splitInputToParts(newVal);
    }
  },
  { immediate: true },
);

const ariaLabel = (index: number) => {
  return index > 1 ? props.codeDigitLabel.replace('[[index]]', index.toString()) : props.codeDigitInitialLabel.replace('[[index]]', index.toString());
};

const errorMessages = computed(() => validation.value.$errors.map((e) => unref(e.$message)));

const moveHandler = (direction: 'back' | 'next', index: number, erase?: boolean) => {
  switch (direction) {
    case 'back':
      back(index, erase);
      break;
    case 'next':
      next(index, erase);
      break;
    default:
      break;
  }
};

const canFocusOnNext = (value: string | null) => value?.trim().length === 6 && props.nextFocusOnSubmitButton;

const fullReset = () => {
  const items: CodeItem = {};
  for (let i = 0; i < 6; i += 1) {
    //
    items[i + 1] = { value: null, focused: false };
  }

  codeItems.value = { ...items };
};

onMounted(() => {
  if (canFocusOnNext(otpCode.value)) {
    focusOnExternalNext();
  } else if (props.focusOnMounted) {
    codeItems.value[1].focused = true;
  }
});

const onPasted = (e: ClipboardEvent) => {
  e.preventDefault();
  if (e.clipboardData?.getData('text')?.replace(/\D/g, '')) {
    const copiedText = e.clipboardData.getData('text').replace(/\D/g, '').slice(0, 6);
    splitInputToParts(copiedText);
    // check where to focus
    if (canFocusOnNext(copiedText)) {
      focusOnExternalNext();
    } else if (codeItems.value[copiedText.length + 1]) {
      codeItems.value[copiedText.length + 1].focused = true;
    }
  }
};

defineExpose({
  fullReset,
});
</script>

<template>
  <div @paste="onPasted">
    <span class="sr-only">{{ enteredCodeScreenReader }}</span>
    <div class="flex items-center justify-center space-x-3">
      <UIPinCodeBox
        v-for="i in Object.keys(codeItems)"
        :key="i"
        v-model="codeItems[Number(i)].value"
        :isFocused="codeItems[Number(i)].focused"
        :aria-label="ariaLabel(Number(i))"
        :aria-required="true"
        :data-testid="`digit-${i}`"
        :isError="errorMessages.length > 0"
        :disabled="disabled"
        @move="(direction, erase) => moveHandler(direction, Number(i), erase)"
        @focusin="codeItems[Number(i)].focused = true"
        @focusout="codeItems[Number(i)].focused = false"
      />
    </div>
    <UIInputErrors :errorMessages="errorMessages" class="mt-1 flex flex-col items-center" />
  </div>
</template>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
